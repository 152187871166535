var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import styles from './ResetPassword.module.scss';
const ResetPassword = () => {
    const { client, user } = useSupabaseContext();
    const navigate = useNavigate();
    const [password, setPassword] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [error, setError] = React.useState('');
    const { sendAlert } = useUtilsContext();
    const validate = () => {
        if (password !== password2) {
            setError('Passwords do not match');
            return false;
        }
        if (password.length < 6) {
            setError('Password must be at least 6 characters');
            return false;
        }
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!validate())
            return;
        setError(null);
        const { data, error } = yield client.auth.updateUser({ password });
        if (error) {
            sendAlert('An error occurred');
        }
        if (data) {
            sendAlert('Successfully reset password');
        }
    });
    React.useEffect(() => {
        if (user.app_metadata.provider !== 'email')
            navigate('/');
    }, []);
    return (React.createElement("div", { className: `${styles.container} fadeIn` },
        React.createElement(Typography, { sx: { userSelect: 'none' }, variant: "h4" }, "Reset password"),
        React.createElement(TextField, { onChange: (e) => setPassword(e.target.value), value: password, type: "password", label: "New password" }),
        React.createElement(TextField, { onChange: (e) => setPassword2(e.target.value), value: password2, type: "password", label: "Confirm password" }),
        error && (React.createElement(Typography, { variant: "subtitle1", className: styles.error }, error)),
        React.createElement("span", null,
            React.createElement(Button, { variant: "contained", onClick: handleSubmit }, "Change Password"))));
};
export default ResetPassword;
