var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import Loader from '../loader/Loader';
import styles from './Profile.module.scss';
const Profile = () => {
    const { user, client } = useSupabaseContext();
    const [profile, setProfile] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [name, setName] = React.useState('');
    const { sendAlert } = useUtilsContext();
    const navigate = useNavigate();
    const fetchProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const { data } = yield client.from('users').select('*').eq('uuid', user.id);
        const profile = data[0];
        setProfile(profile);
        setName(profile.name);
        setLoading(false);
    });
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (name.trim() === profile.name) {
            sendAlert('Name is already ' + name, 'error');
            return;
        }
        if (name.trim() === '') {
            sendAlert('Please enter a name', 'error');
            return;
        }
        const { error } = yield client
            .from('users')
            .update({ uuid: user.id, name: name.trim() })
            .eq('uuid', user.id);
        if (!error) {
            sendAlert('Name updated to ' + name);
        }
        else {
            sendAlert('An error occurred while updating name', 'error');
        }
        fetchProfile();
    });
    React.useEffect(() => {
        fetchProfile();
    }, [user]);
    return (React.createElement(Box, { sx: {
            width: '100%',
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
        }, className: "fadeIn" },
        React.createElement(Typography, { variant: "h4", sx: { mb: 1 } }, "Edit Profile"),
        loading || profile === null ? (React.createElement(Loader, null)) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body1" },
                "Email: ", profile === null || profile === void 0 ? void 0 :
                profile.email),
            React.createElement("div", { className: styles.nameContainer },
                React.createElement(TextField, { label: "Name", value: name, onChange: (e) => setName(e.target.value) }),
                React.createElement("span", null,
                    React.createElement(Button, { onClick: handleSubmit, variant: "contained" }, "Update name"))),
            user.app_metadata.provider === 'email' && (React.createElement("span", null,
                React.createElement(Button, { variant: "contained", onClick: () => navigate('/reset') }, "Reset Password")))))));
};
export default Profile;
