var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Typography } from '@mui/material';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import Loader from '../loader/Loader';
import Leaderboard from './Leaderboard/Leaderboard';
import styles from './LeagueResults.module.scss';
import RaceAccordion from './RaceAccordion/RaceAccordion';
const LeagueResults = ({ leagueId }) => {
    const [loading, setLoading] = React.useState(true);
    const { client, user, driversMap, races } = useSupabaseContext();
    const [leagueMembers, setLeagueMembers] = React.useState(new Map());
    const [nextRaceRoundId, setNextRaceRoundId] = React.useState(-1);
    const [leagueResultsMap, setLeagueResultsMap] = React.useState(new Map());
    const [indexOfNextRace, setIndexOfNextRace] = React.useState(-1);
    const { sendAlert } = useUtilsContext();
    const fetchResults = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const { data } = yield client
            .from('league_results')
            .select(`
          *,
          leagues (name, invite_code),
          races (*)
        `)
            .eq('league_id', leagueId)
            .eq('year', 2024);
        const { data: leagueMembers } = yield client
            .from('league_members')
            .select(`*,
          users (*)
        `)
            .eq('league_id', leagueId);
        const map = new Map();
        const tempMembersMap = new Map();
        for (const member of leagueMembers) {
            map.set(member.user_uuid, new Map());
            tempMembersMap.set(member.user_uuid, member);
        }
        for (const leagueResult of data) {
            const userId = leagueResult.user_uuid;
            map.get(userId).set(leagueResult.race_id, leagueResult);
        }
        setLeagueResultsMap(map);
        setLeagueMembers(tempMembersMap);
        const nextRaceIndex = races.findIndex((value) => !passedQualiDate(value));
        setIndexOfNextRace(nextRaceIndex);
        setNextRaceRoundId(nextRaceIndex !== -1 ? races[nextRaceIndex].id : -1);
        setLoading(false);
    });
    const passedQualiDate = (race) => {
        const raceDate = Date.parse(`${race.quali_date} ${race.quali_time}`);
        return raceDate < Date.now();
    };
    const submitDriver = (driverId, dnfDriverId, rowId) => __awaiter(void 0, void 0, void 0, function* () {
        const { error } = yield client
            .from('league_results')
            .update({
            driver_id: driverId,
            dnf_driver_id: dnfDriverId,
        })
            .eq('id', rowId);
        if (!error) {
            sendAlert('Submitted!');
            fetchResults();
        }
        else {
            sendAlert('Failed to submit drivers - please try again later', 'error');
        }
    });
    React.useEffect(() => {
        if (leagueId === -1)
            return;
        fetchResults();
    }, [leagueId]);
    if (leagueId === -1)
        return null;
    if (loading)
        return (React.createElement("div", { className: `fadeIn ${styles.loaderContainer}` },
            React.createElement(Loader, null)));
    const onChange = (inView, entry) => {
        if (inView) {
            entry.target.classList.add('fadeInTop');
        }
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement(Leaderboard, { leagueResultsMap: leagueResultsMap, usersMap: leagueMembers }),
        indexOfNextRace !== -1 && (React.createElement("div", { className: "fadeIn" },
            React.createElement(Typography, { variant: "h4", sx: { mb: 2 } }, "Upcoming Races"),
            races.slice(indexOfNextRace, undefined).map((race) => (React.createElement(InView, { onChange: onChange, key: race.race_name, className: "hidden" },
                React.createElement(RaceAccordion, { race: race, nextRaceRoundId: nextRaceRoundId, leagueResultsMap: leagueResultsMap, submitDriver: submitDriver, leagueMembers: leagueMembers })))))),
        React.createElement("div", { className: "fadeIn" },
            React.createElement(Typography, { variant: "h4", sx: { mb: 2 } }, "Past Races"),
            races
                .slice(0, indexOfNextRace)
                .reverse()
                .map((race) => (React.createElement(InView, { onChange: onChange, key: race.race_name, className: "hidden" },
                React.createElement(RaceAccordion, { race: race, nextRaceRoundId: nextRaceRoundId, leagueResultsMap: leagueResultsMap, submitDriver: submitDriver, leagueMembers: leagueMembers })))))));
};
export default LeagueResults;
