import { Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, } from '@mui/material';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSupabaseContext } from '../../../contexts/SupabaseContext';
import { driverName, pointsMap } from '../../../helpers/helpers';
import styles from './RaceResultsTable.module.scss';
const RaceResultsTable = ({ raceResults }) => {
    const { driversMap } = useSupabaseContext();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { ref, inView, entry } = useInView();
    React.useEffect(() => {
        inView &&
            Array.from(entry.target.children).forEach((child) => child.classList.add(`fadeInListDelay`));
    }, [inView]);
    return (React.createElement(Table, { sx: { width: '100%', overflow: 'auto' }, size: "small" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, { sx: { width: '10%' } }, "Position"),
                React.createElement(TableCell, { align: "right", sx: { width: '50%' } }, "Driver"),
                !isMobile && (React.createElement(React.Fragment, null,
                    React.createElement(TableCell, { align: "right", sx: { width: '20%' } }, "F1 Points"),
                    React.createElement(TableCell, { align: "right", sx: { width: '20%' } }, "P10 Points"))))),
        React.createElement(TableBody, { ref: ref }, raceResults.map((result) => (React.createElement(TableRow, { key: result.driver_id },
            React.createElement(TableCell, null, result.position),
            React.createElement(TableCell, { sx: {
                    maxWidth: 0,
                    verticalAlign: 'middle',
                    borderBottom: 'none',
                }, align: "right" },
                React.createElement("div", { className: styles.driverName },
                    React.createElement("span", { className: styles.text }, driverName(driversMap.get(result.driver_id), isMobile, '-', true)),
                    React.createElement("img", { src: `/images/${driversMap.get(result.driver_id).constructor}.png`, height: 20 }))),
            !isMobile && (React.createElement(React.Fragment, null,
                React.createElement(TableCell, { align: "right", sx: {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: 0,
                        borderBottom: 'none',
                    } }, result.points),
                React.createElement(TableCell, { align: "right" }, pointsMap[result.position])))))))));
};
export default RaceResultsTable;
