import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useSupabaseContext } from '../../../contexts/SupabaseContext';
import { driverName } from '../../../helpers/helpers';
import DataTable from '../../table/DataTable';
import styles from './ResultsTable.module.scss';
const ResultsTable = ({ leagueMembers, leagueResultsMap, race, }) => {
    const { raceResultsDriverMap, driversMap } = useSupabaseContext();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [tableData, setTableData] = React.useState(null);
    const getPointsColumn = (userId, raceId) => {
        var _a, _b, _c;
        const points = (_a = leagueResultsMap.get(userId).get(raceId)) === null || _a === void 0 ? void 0 : _a.points_gained;
        if (points === null)
            return '-';
        const pos = (_c = (_b = raceResultsDriverMap
            .get(raceId)
            .get(leagueResultsMap.get(userId).get(raceId).driver_id)) === null || _b === void 0 ? void 0 : _b.position) !== null && _c !== void 0 ? _c : null;
        return `${points} ${pos != null ? `(${pos})` : ''}`;
    };
    const getDnfPointsColumn = (userId, raceId) => {
        const points = leagueResultsMap.get(userId).get(raceId).dnf_points_gained;
        if (points === null)
            return '-';
        return `${points}`;
    };
    React.useEffect(() => {
        init();
    }, []);
    const getDriver = (uuid) => {
        return (React.createElement("div", { className: styles.driverName },
            React.createElement("span", { className: styles.text }, driverName(driversMap.get(leagueResultsMap.get(uuid).get(race.id).driver_id), isMobile, '-')),
            !!driversMap.get(leagueResultsMap.get(uuid).get(race.id).driver_id) && (React.createElement("img", { src: `/images/${driversMap.get(leagueResultsMap.get(uuid).get(race.id).driver_id)
                    .constructor}.png`, height: 20 }))));
    };
    const getDnfDriver = (uuid) => {
        return (React.createElement("div", { className: styles.driverName },
            React.createElement("span", { className: styles.text }, leagueResultsMap.get(uuid).get(race.id).dnf_driver_id === 266
                ? 'NO DNF!'
                : driverName(driversMap.get(leagueResultsMap.get(uuid).get(race.id).dnf_driver_id), isMobile, '-')),
            driversMap.get(leagueResultsMap.get(uuid).get(race.id).dnf_driver_id) && (React.createElement("img", { src: `/images/${driversMap.get(leagueResultsMap.get(uuid).get(race.id).dnf_driver_id).constructor}.png`, height: 20 }))));
    };
    const headerData = [
        { header: 'User', width: '30%' },
        { header: 'Picks (P10/DNF)', mobileHeader: 'Picks', width: '40%' },
        { header: 'Points (Pos)', mobileHeader: 'Points', width: '30%' },
    ];
    const rows = [];
    const secondRows = [];
    const init = () => {
        Array.from(leagueMembers.entries()).forEach(([uuid, value]) => {
            const row = [];
            row.push({ data: value.users.name });
            row.push({ data: getDriver(uuid) });
            row.push({ data: getPointsColumn(uuid, race.id) });
            rows.push(row);
            const secondRow = [];
            secondRow.push({ data: '' });
            secondRow.push({ data: getDnfDriver(uuid) });
            secondRow.push({ data: getDnfPointsColumn(uuid, race.id) });
            secondRows.push(secondRow);
        });
        setTableData({ rows, secondRows });
    };
    if (tableData === null)
        return null;
    return (React.createElement(DataTable, { headerData: headerData, rowData: tableData.rows, secondRowData: tableData.secondRows, pagination: true }));
};
export default ResultsTable;
