var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Delete, Share } from '@mui/icons-material';
import { Box, Button, IconButton, Tab, Tabs, TextField, Tooltip, Typography, } from '@mui/material';
import React, { useRef } from 'react';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import { joinLeague } from '../../services/database';
import LeagueResults from '../league_results/LeagueResults';
import Loader from '../loader/Loader';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import styles from './Leagues.module.scss';
const Leagues = () => {
    var _a;
    const leagueNameRef = useRef();
    const leagueCodeRef = useRef();
    const [leagueId, setLeagueId] = React.useState(-1);
    const { client, user } = useSupabaseContext();
    const [loading, setLoading] = React.useState(true);
    const [joinedLeagues, setJoinedLeagues] = React.useState(new Map());
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const { sendAlert } = useUtilsContext();
    const onCreateHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        // add the league to your user profile
        // create league, access code and add user to it
        // create access code -> league mapping
        const leagueName = leagueNameRef.current.value;
        if (leagueName.length === 0)
            return;
        setLoading(true);
        const { data, error } = yield client.rpc('create_league', {
            i_code: crypto.randomUUID().slice(0, 6),
            l_name: leagueName,
        });
        if (error) {
            sendAlert(`Failed to create league, please try again later. ${error.details}, ${error.message}`, 'error');
        }
        else {
            sendAlert('Successfully created league ' + leagueName);
        }
        setLoading(false);
        fetchLeagues();
    });
    const onJoinHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        // add user to the league
        // add league to the user
        const leagueCode = leagueCodeRef.current.value;
        if (leagueCode.length === 0) {
            sendAlert('League code must not be empty', 'error');
            return;
        }
        setLoading(true);
        try {
            yield joinLeague(client, user, leagueCode);
        }
        catch (e) {
            sendAlert(e.message, 'error');
        }
        setLoading(false);
        fetchLeagues();
    });
    const fetchLeagues = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setLoading(true);
        // fetch leagues that you belong to
        const { data } = yield client
            .from('league_members')
            .select(`
          league_id, leagues (*)
        `)
            .eq('user_uuid', user.id);
        const tempLeaguesMap = new Map();
        for (const league of data) {
            tempLeaguesMap.set(league.league_id, league.leagues);
        }
        setJoinedLeagues(tempLeaguesMap);
        setLeagueId(((_b = tempLeaguesMap.values().next().value) === null || _b === void 0 ? void 0 : _b.id) || -1);
        setLoading(false);
    });
    React.useEffect(() => {
        if (user === null)
            return;
        fetchLeagues();
    }, [user]);
    const handleInviteClick = () => __awaiter(void 0, void 0, void 0, function* () {
        yield navigator.clipboard.writeText(window.location.origin +
            '/join/' +
            joinedLeagues.get(leagueId).invite_code);
        sendAlert('Copied invite to clipboard!');
    });
    const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        yield client.rpc('delete_league', { league_id: leagueId });
        fetchLeagues();
    });
    const isOwner = ((_a = joinedLeagues.get(leagueId)) === null || _a === void 0 ? void 0 : _a.created_by_uuid) === (user === null || user === void 0 ? void 0 : user.id);
    const validLeague = leagueId !== -1;
    if (loading)
        return React.createElement(Loader, null);
    return (React.createElement("div", { className: `${styles.container} fadeIn` },
        validLeague ? (React.createElement(React.Fragment, null,
            React.createElement(DeleteDialog, { open: deleteDialogOpen, setOpen: setDeleteDialogOpen, name: joinedLeagues.get(leagueId).name, handleDelete: handleDelete }),
            React.createElement("div", { className: styles.title },
                React.createElement(Typography, { variant: "h4" }, joinedLeagues.get(leagueId).name),
                validLeague && (React.createElement("span", null,
                    React.createElement("span", null,
                        React.createElement(Tooltip, { title: "Copy invite code" },
                            React.createElement(IconButton, { onClick: handleInviteClick },
                                React.createElement(Share, { color: "primary" })))),
                    isOwner && (React.createElement("span", null,
                        React.createElement(Tooltip, { title: "Delete league" },
                            React.createElement(IconButton, { onClick: () => setDeleteDialogOpen(true) },
                                React.createElement(Delete, { color: "primary" })))))))),
            React.createElement(Box, { sx: { width: '100%', borderBottom: 1, borderColor: 'divider' } },
                React.createElement(Tabs, { value: leagueId, onChange: (e, v) => setLeagueId(v), variant: "scrollable", scrollButtons: "auto", sx: {
                        '& .MuiTabScrollButton-root': {
                            opacity: '0.8 !important',
                        },
                    } }, Array.from(joinedLeagues.values()).map((league) => (React.createElement(Tab, { label: league.name, value: league.id, key: league.id }))))),
            React.createElement(LeagueResults, { leagueId: leagueId }))) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "h4" }, "Leagues"),
            React.createElement(Typography, null, "Create or join a league to play!"))),
        React.createElement("div", { className: styles.leagueSubmitContainer },
            React.createElement(TextField, { helperText: "Enter your league name", inputRef: leagueNameRef, autoComplete: "off" }, "League name"),
            React.createElement("span", null,
                React.createElement(Button, { variant: "contained", onClick: onCreateHandler }, "Create a league"))),
        React.createElement("div", { className: styles.leagueSubmitContainer },
            React.createElement(TextField, { helperText: "Enter your league code here", inputRef: leagueCodeRef, autoComplete: "off" }, "League code"),
            React.createElement("span", null,
                React.createElement(Button, { variant: "contained", onClick: onJoinHandler }, "Join a league")))));
};
export default Leagues;
