import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, List, ListItem, ListItemButton, ListItemText, } from '@mui/material';
import React from 'react';
const NestedNavItem = ({ item, handleMenuClick, }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen((prev) => !prev);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { disablePadding: true },
            React.createElement(ListItemButton, { sx: { textAlign: 'center' }, onClick: handleOpen },
                React.createElement(ListItemText, { primary: item.name }),
                open ? (React.createElement(ExpandLess, { sx: { position: 'absolute', right: '16px' } })) : (React.createElement(ExpandMore, { sx: { position: 'absolute', right: '16px' } })))),
        React.createElement(Collapse, { in: open, timeout: "auto", unmountOnExit: true },
            React.createElement(Divider, { sx: { width: '6rem', margin: 'auto' } }),
            React.createElement(List, { component: "div", disablePadding: true }, item.menu.map((name) => (React.createElement(ListItemButton, { sx: { textAlign: 'center' }, onClick: () => handleMenuClick(name), key: name },
                React.createElement(ListItemText, { primary: name }))))))));
};
export default NestedNavItem;
