var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import styles from './Landing.module.scss';
const Landing = () => {
    const { user, client } = useSupabaseContext();
    const [numberUsers, setNumberUsers] = React.useState(null);
    const [numberLeagues, setNumberLeagues] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    const handleClick = () => {
        if (user) {
            navigate('/leagues');
        }
        else {
            navigate('/login');
        }
    };
    const init = () => __awaiter(void 0, void 0, void 0, function* () {
        let { data: users } = yield client.rpc('get_number_of_users');
        let { data: leagues } = yield client.rpc('get_number_of_leagues');
        setNumberLeagues(leagues);
        setNumberUsers(users);
        setLoading(false);
    });
    React.useEffect(() => {
        init();
    }, []);
    return (React.createElement("span", { className: styles.container },
        React.createElement("div", { className: styles.heading },
            React.createElement(Typography, { variant: "h1", textAlign: "center", className: styles.title, sx: {} }, "P10 Racing"),
            React.createElement(Typography, { component: "span", variant: "h6", textAlign: "center", className: styles.subtitle }, "The ultimate F1 fantasy league!")),
        React.createElement("div", { className: styles.button },
            React.createElement(Button, { variant: "outlined", onClick: handleClick }, user ? 'Join a league' : 'Get started')),
        !loading && (React.createElement(Typography, { variant: "caption", textAlign: "center", className: styles.stats },
            "Join ",
            numberUsers,
            " users competing in ",
            numberLeagues,
            " different leagues!"))));
};
export default Landing;
