import { Card, CardContent, Divider, Typography, useMediaQuery, } from '@mui/material';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { formatRaceDateTime } from '../../helpers/helpers';
import QualiResultsTable from './QualiResultsTable/QualiResultsTable';
import styles from './Qualifying.module.scss';
const Qualifying = () => {
    const { qualiResultsMap, racesMap } = useSupabaseContext();
    const getQualiDate = (raceId) => {
        return racesMap.get(raceId).quali_date;
    };
    const getQualiTime = (raceId) => {
        return racesMap.get(raceId).quali_time;
    };
    const passedQualiDate = (raceId) => {
        const raceDate = Date.parse(`${racesMap.get(raceId).quali_date} ${racesMap.get(raceId).quali_time}`);
        return raceDate < Date.now();
    };
    const indexOfNextRace = Array.from(qualiResultsMap.keys()).findIndex((raceId) => !passedQualiDate(raceId));
    const isMobile = useMediaQuery('(max-width:600px)');
    const onChange = (inView, entry) => {
        if (inView) {
            entry.target.classList.add('fadeIn');
        }
    };
    return (React.createElement("div", { className: `${styles.container} fadeIn` },
        indexOfNextRace !== 0 && (React.createElement(Typography, { variant: "h4" }, "Qualifying Results")),
        Array.from(qualiResultsMap.entries())
            .slice(0, indexOfNextRace)
            .reverse()
            .map(([race_id, resultsArray]) => (React.createElement(InView, { style: { width: '100%' }, onChange: onChange, key: race_id, className: "hidden" },
            React.createElement(Card, { sx: { width: '100%' }, elevation: 2 },
                React.createElement(CardContent, null,
                    React.createElement("div", { className: styles.cardTitle },
                        React.createElement(Typography, { variant: "h5" }, racesMap.get(race_id).race_name),
                        React.createElement(Typography, { variant: "subtitle1" }, formatRaceDateTime(getQualiDate(race_id), getQualiTime(race_id), isMobile))),
                    React.createElement(Divider, { sx: { pt: 1, mb: 1 } }),
                    React.createElement(QualiResultsTable, { qualiResults: resultsArray })))))),
        indexOfNextRace !== -1 && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "h4" }, "Upcoming Qualifying Results"),
            Array.from(qualiResultsMap.entries())
                .slice(indexOfNextRace, undefined)
                .map(([race_id]) => (React.createElement(InView, { style: { width: '100%' }, onChange: onChange, key: race_id, className: "hidden" },
                React.createElement(Card, { sx: { width: '100%' }, elevation: 2 },
                    React.createElement(CardContent, null,
                        React.createElement("div", { className: styles.cardTitle },
                            React.createElement(Typography, { variant: "h5" }, racesMap.get(race_id).race_name),
                            React.createElement(Typography, { variant: "subtitle1" }, formatRaceDateTime(getQualiDate(race_id), getQualiTime(race_id), isMobile))))))))))));
};
export default Qualifying;
