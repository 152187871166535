import { Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, } from '@mui/material';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSupabaseContext } from '../../../contexts/SupabaseContext';
import { driverName } from '../../../helpers/helpers';
import styles from './QualiResultsTable.module.scss';
const QualiResultsTable = ({ qualiResults, }) => {
    const { driversMap } = useSupabaseContext();
    const { ref, inView, entry } = useInView();
    const headers = ['Position', 'Driver', 'Q1', 'Q2', 'Q3'];
    const isMobile = useMediaQuery('(max-width:600px)');
    const showHeader = (index) => {
        if (!isMobile)
            return true;
        if (index > 1)
            return false;
        return true;
    };
    React.useEffect(() => {
        inView &&
            Array.from(entry.target.children).forEach((child) => child.classList.add(`fadeInListDelay`));
    }, [inView]);
    if (!qualiResults.length)
        return React.createElement(Typography, null, "TBD");
    return (React.createElement(Table, { size: "small" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, null, headers.map((header, index) => showHeader(index) ? (React.createElement(TableCell, { align: index > 0 ? 'right' : 'left', key: header }, header)) : null))),
        React.createElement(TableBody, { ref: ref }, qualiResults.map((result) => (React.createElement(TableRow, { key: result.id },
            React.createElement(React.Fragment, null,
                React.createElement(TableCell, { width: "10%" }, result.position),
                React.createElement(TableCell, { width: "50%", align: "right" },
                    React.createElement("div", { className: styles.driverName },
                        React.createElement("span", null, driverName(driversMap.get(result.driver_id), false)),
                        React.createElement("img", { src: `/images/${driversMap.get(result.driver_id).constructor}.png`, height: 20 }))),
                !isMobile && (React.createElement(React.Fragment, null,
                    React.createElement(TableCell, { width: "15%", align: "right" }, result.q1),
                    React.createElement(TableCell, { width: "15%", align: "right" }, result.q2),
                    React.createElement(TableCell, { width: "15%", align: "right" }, result.q3))))))))));
};
export default QualiResultsTable;
