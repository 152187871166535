import { Typography } from '@mui/material';
import React from 'react';
import styles from './Standings.module.scss';
import P10StandingsTable from './p10Standings/P10StandingsTable';
const Standings = () => {
    return (React.createElement("div", { className: `${styles.container} fadeIn` },
        React.createElement(Typography, { variant: "h4" }, "P10 Standings"),
        React.createElement(P10StandingsTable, null)));
};
export default Standings;
