import { Card, CardContent, Divider, Typography, useMediaQuery, } from '@mui/material';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { useSupabaseContext, } from '../../contexts/SupabaseContext';
import { formatRaceDateTime } from '../../helpers/helpers';
import styles from './RaceResults.module.scss';
import RaceResultsTable from './RaceResultsTable/RaceResultsTable';
const RaceResults = () => {
    const { raceResultsMap, races } = useSupabaseContext();
    const isMobile = useMediaQuery('(max-width:600px)');
    const onChange = (inView, entry) => {
        if (inView) {
            entry.target.classList.add('fadeIn');
        }
    };
    const passedRaceDate = (race) => {
        const raceDate = Date.parse(`${race.date} ${race.time}`);
        return raceDate < Date.now();
    };
    const indexOfNextRace = races.findIndex((race) => !passedRaceDate(race));
    return (React.createElement("div", { className: 'fadeIn' },
        indexOfNextRace !== 0 && (React.createElement("div", { className: styles.heading },
            React.createElement(Typography, { variant: "h4", sx: { mb: 2 } }, "Race Results"))),
        React.createElement("div", { className: styles.container }, races
            .slice(0, indexOfNextRace)
            .reverse()
            .map((race) => (React.createElement(InView, { onChange: onChange, style: { width: '100%' }, key: race.id, className: "hidden" },
            React.createElement(Card, { sx: { width: '100%' }, elevation: 2 },
                React.createElement(CardContent, { sx: { display: 'flex', flexDirection: 'column' } },
                    React.createElement("div", { className: styles.raceTitle },
                        React.createElement(Typography, { variant: "h5" }, race.race_name),
                        React.createElement(Typography, { variant: "subtitle1" }, formatRaceDateTime(race.date, race.time, isMobile))),
                    raceResultsMap.get(race.id).length > 0 && (React.createElement(React.Fragment, null,
                        React.createElement(Divider, { sx: { pt: 1, mb: 1 } }),
                        React.createElement("div", { className: styles.heading },
                            React.createElement(Typography, { variant: "body1" }, "Race Results")),
                        React.createElement(RaceResultsTable, { raceResults: raceResultsMap.get(race.id) }))))))))),
        indexOfNextRace !== -1 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.heading },
                React.createElement(Typography, { variant: "h4", sx: { mb: 2 } }, "Upcoming Race Results")),
            React.createElement("div", { className: styles.container }, races.slice(indexOfNextRace, undefined).map((race) => (React.createElement(InView, { onChange: onChange, style: { width: '100%' }, key: race.id, className: "hidden" },
                React.createElement(Card, { sx: { width: '100%' }, elevation: 2 },
                    React.createElement(CardContent, null,
                        React.createElement("div", { className: styles.raceTitle },
                            React.createElement(Typography, { variant: "h5" }, race.race_name),
                            React.createElement(Typography, { variant: "subtitle1" }, formatRaceDateTime(race.date, race.time, isMobile)))))))))))));
};
export default RaceResults;
