var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { getRaceByRoundNumber, updateQualiResultWithFinish, updateRaceResultWithFinish, } from './database';
export const setRaces = (client) => {
    axios.get('https://ergast.com/api/f1/current.json').then((res) => {
        const races = res.data.MRData.RaceTable.Races;
        console.log(races);
        // races.forEach((race) => {
        //   insertIntoRaces(client, race)
        // })
    });
};
export const setConstructors = (client) => {
    axios.get('https://ergast.com/api/f1/2024/constructors.json').then((res) => {
        const constructors = res.data.MRData.ConstructorTable.Constructors;
        console.log(constructors);
        // TODO - DOUBLE CHECK ERGAST API CONSTRUCTORS AGAINST DB RESULTS for 2024
        // const tempConstructors = [
        //   {
        //     constructorId: 'alpine',
        //     name: 'BWT Alpine F1 Team',
        //   },
        //   {
        //     constructorId: 'aston_martin',
        //     name: 'Aston Martin Aramco F1 Team',
        //   },
        //   {
        //     constructorId: 'ferrari',
        //     name: 'Scuderia Ferrari',
        //   },
        //   {
        //     constructorId: 'haas',
        //     name: 'MoneyGram Haas F1 Team',
        //   },
        //   {
        //     constructorId: 'kick_sauber',
        //     name: 'Stake F1 Team Kick Sauber',
        //   },
        //   {
        //     constructorId: 'mclaren',
        //     name: '	McLaren Formula 1 Team',
        //   },
        //   {
        //     constructorId: 'mercedes',
        //     name: 'Mercedes-AMG PETRONAS F1 Team',
        //   },
        //   {
        //     constructorId: 'rb',
        //     name: 'Visa Cash App RB Formula One Team',
        //   },
        //   {
        //     constructorId: 'red_bull',
        //     name: 'Oracle Red Bull Racing',
        //   },
        //   {
        //     constructorId: 'williams',
        //     name: 'Williams Racing',
        //   },
        // ]
        // constructors.forEach((constructor) => {
        //   insertIntoConstructors(client, constructor)
        // })
    });
};
export const setDrivers = (client) => {
    axios.get('https://ergast.com/api/f1/2024/drivers.json').then((res) => {
        const drivers = res.data.MRData.DriverTable.Drivers;
        console.log(drivers);
        // drivers.forEach((driver) => {
        //   insertIntoDrivers(client, driver)
        // })
    });
};
export const setRaceResultsByRound = (client, round, driversIdMap) => {
    if (round < 1)
        return;
    const year = 2024;
    return axios
        .get(`https://ergast.com/api/f1/${year}/${round}/results.json`)
        .then((res) => __awaiter(void 0, void 0, void 0, function* () {
        const results = res.data.MRData.RaceTable.Races[0].Results;
        const race = yield getRaceByRoundNumber(client, round);
        results.forEach((result) => {
            updateRaceResultWithFinish(client, result, race.data[0].id, driversIdMap, year);
        });
    }));
};
export const setQualiResultsByRound = (client, round, driversIdMap) => {
    if (round < 1)
        return;
    const year = 2024;
    return axios
        .get(`https://ergast.com/api/f1/${year}/${round}/qualifying.json`)
        .then((res) => __awaiter(void 0, void 0, void 0, function* () {
        const results = res.data.MRData.RaceTable.Races[0].QualifyingResults;
        const race = yield getRaceByRoundNumber(client, round);
        results.forEach((result) => {
            updateQualiResultWithFinish(client, result, race.data[0].id, driversIdMap.get(result.Driver.driverId.trim()).id, year);
        });
    }));
};
