import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import React from 'react';
const DeleteDialog = ({ open, setOpen, name, handleDelete, }) => {
    const handleClose = () => {
        setOpen(false);
    };
    const deleteOnClickHandler = () => {
        handleDelete();
        handleClose();
    };
    return (React.createElement(Dialog, { open: open, onClose: handleClose },
        React.createElement(DialogTitle, null, `Delete ${name}?`),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, null,
                "Are you sure you want to delete ",
                name,
                "? This action CANNOT be undone!")),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: "outlined", onClick: handleClose }, "Cancel"),
            React.createElement(Button, { variant: "contained", color: "error", onClick: deleteOnClickHandler }, "Delete"))));
};
export default DeleteDialog;
