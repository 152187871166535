import { ArrowDropDown, ArrowDropUp, EmojiEvents, Remove, } from '@mui/icons-material';
import { Button, Card, List, ListItem, ListItemAvatar, ListItemText, Typography, } from '@mui/material';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useUtilsContext } from '../../../contexts/UtilsContext';
import styles from './Leaderboard.module.scss';
const Leaderboard = ({ leagueResultsMap, usersMap, }) => {
    const [leaderboardArray, setLeaderboardArray] = React.useState([]);
    const [relativeRankings, setRelativeRankings] = React.useState(null);
    const { mode } = useUtilsContext();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const { ref, inView, entry } = useInView();
    const init = () => {
        const tempArray = [];
        const tempArrayPreviousWeek = [];
        for (const [id, raceMap] of Array.from(leagueResultsMap.entries())) {
            let p10Total = 0;
            let dnfTotal = 0;
            const raceMapArray = Array.from(raceMap.values())
                .filter((race) => race.points_gained !== null)
                .sort((a, b) => a.races.round_number - b.races.round_number);
            for (let i = 0; i < raceMapArray.length - 1; i++) {
                p10Total += raceMapArray[i].points_gained;
                dnfTotal += raceMapArray[i].dnf_points_gained;
            }
            const totalPreviousWeek = p10Total + dnfTotal;
            const prevWeekObj = {
                user: usersMap.get(id).users.name,
                userId: id,
                total: totalPreviousWeek,
                dnfTotal: dnfTotal,
                p10Total: p10Total,
            };
            if (raceMapArray.length > 0) {
                p10Total += raceMapArray[raceMapArray.length - 1].points_gained;
                dnfTotal += raceMapArray[raceMapArray.length - 1].dnf_points_gained;
            }
            const total = dnfTotal + p10Total;
            const obj = {
                user: usersMap.get(id).users.name,
                userId: id,
                total: total,
                dnfTotal: dnfTotal,
                p10Total: p10Total,
            };
            tempArrayPreviousWeek.push(prevWeekObj);
            tempArray.push(obj);
        }
        const previousWeekRankingArray = tempArrayPreviousWeek
            .sort((a, b) => b.total - a.total)
            .map((e) => e.userId);
        tempArray.sort((a, b) => b.total - a.total);
        const rrMap = new Map();
        for (let i = 0; i < tempArray.length; i++) {
            const user = tempArray[i];
            const positionsGained = previousWeekRankingArray.indexOf(user.userId) - i;
            rrMap.set(user.userId, positionsGained);
        }
        setRelativeRankings(rrMap);
        setLeaderboardArray([...tempArray]);
    };
    React.useEffect(() => {
        init();
    }, []);
    React.useEffect(() => {
        inView &&
            Array.from(entry.target.children).forEach((e) => e.classList.add('fadeInListDelay'));
    }, [inView, mode, isExpanded]);
    const colorMap = {
        0: '#FFD700',
        1: '#C0C0C0',
        2: '#CD7F32',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: "h4", className: "fadeIn" }, "Leaderboard"),
        React.createElement(Card, { elevation: 2, sx: { pt: 0, pl: 2 }, className: "fadeIn" },
            React.createElement(List, { sx: { pt: 1, pb: 1, pr: 1 }, ref: ref },
                leaderboardArray
                    .slice(0, isExpanded ? undefined : 3)
                    .map((obj, index) => (React.createElement(ListItem, { key: obj.user, sx: { opacity: 0 }, secondaryAction: relativeRankings.get(obj.userId) > 0 ? (React.createElement("span", { className: styles.icons },
                        React.createElement(ArrowDropUp, { htmlColor: "green" }),
                        React.createElement("span", null, `(${relativeRankings.get(obj.userId)})`))) : relativeRankings.get(obj.userId) < 0 ? (React.createElement("span", { className: styles.icons },
                        React.createElement(ArrowDropDown, { htmlColor: "red" }),
                        ' ',
                        React.createElement("span", null, `(${relativeRankings.get(obj.userId)})`))) : (React.createElement("span", { className: styles.icons },
                        React.createElement(Remove, { htmlColor: "gray" }),
                        ' ',
                        React.createElement("span", null, `(${relativeRankings.get(obj.userId)})`))) },
                    index < 3 && (React.createElement(ListItemAvatar, null,
                        React.createElement(EmojiEvents, { htmlColor: colorMap[index] }))),
                    React.createElement(ListItemText, { primary: obj.user, secondary: `${obj.total} | P10 - ${obj.p10Total} | DNF - ${obj.dnfTotal}`, inset: index > 2, sx: { width: 500 } })))),
                React.createElement("div", { className: styles.buttonContainer }, leaderboardArray.length > 3 && (React.createElement(Button, { onClick: () => setIsExpanded((prev) => !prev) }, isExpanded ? 'Show less' : 'Show more')))))));
};
export default Leaderboard;
