import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { inject } from '@vercel/analytics';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import styles from './App.module.scss';
import { router } from './Router';
import Loader from './components/loader/Loader';
import { useSupabaseContext } from './contexts/SupabaseContext';
import { useUtilsContext } from './contexts/UtilsContext';
const App = () => {
    const underConstruction = false;
    const { loading } = useSupabaseContext();
    const { SnackBar, theme } = useUtilsContext();
    inject();
    if (underConstruction)
        return (React.createElement(React.Fragment, null,
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(CssBaseline, null),
                React.createElement("div", { style: {
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    } },
                    React.createElement("p", null, "P10 Racing is under construction for the 2024 season! We will be back soon.")))));
    return (React.createElement(React.Fragment, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            loading ? (React.createElement("div", { className: styles.loadingContainer },
                React.createElement(Loader, null))) : (React.createElement(React.Fragment, null,
                React.createElement(SnackBar, null),
                React.createElement(RouterProvider, { router: router }))))));
};
export default App;
