import { Alert, Snackbar, createTheme, responsiveFontSizes, } from '@mui/material';
import React, { createContext } from 'react';
const UtilsContext = createContext(null);
UtilsContext.displayName = 'Utils Context';
const useContext = () => {
    const [snackBarState, setSnackBarState] = React.useState({
        open: false,
        message: '',
        variant: 'success',
        timeout: 6000,
    });
    const [mode, setMode] = React.useState(localStorage.getItem('theme') || 'light');
    const sendAlert = (message, variant = 'success', timeout = 6000) => {
        setSnackBarState((prev) => (Object.assign(Object.assign({}, prev), { open: true, message: message, variant: variant, timeout: timeout })));
    };
    const handleClose = () => {
        setSnackBarState((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    const theme = React.useMemo(() => responsiveFontSizes(createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    body: {
                        borderBottom: 'none',
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        background: 'transparent',
                        backdropFilter: 'blur(100px)',
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        background: 'transparent',
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        background: 'transparent',
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundImage: mode === 'light'
                            ? `linear-gradient(141deg, #f2f2f2, #6dc9ff)`
                            : `linear-gradient(141deg, #433c3c, #024d79)`,
                    },
                },
            },
        },
        typography: {
            h6: {
                fontWeight: 400,
            },
        },
        palette: {
            mode: mode,
        },
    })), [mode]);
    const toggleColorMode = () => {
        localStorage.setItem('theme', mode === 'light' ? 'dark' : 'light');
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };
    const SnackBar = () => (React.createElement(Snackbar, { open: snackBarState.open, autoHideDuration: snackBarState.timeout, onClose: handleClose },
        React.createElement(Alert, { onClose: handleClose, severity: snackBarState.variant }, snackBarState.message)));
    return {
        SnackBar,
        sendAlert,
        theme,
        toggleColorMode,
        mode,
    };
};
export const useUtilsContext = () => {
    const context = React.useContext(UtilsContext);
    if (!context) {
        throw new Error('Utils Context undefined');
    }
    return context;
};
export const UtilsProvider = ({ children }) => {
    const value = useContext();
    return React.createElement(UtilsContext.Provider, { value: value }, children);
};
