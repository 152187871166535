var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createClient } from '@supabase/supabase-js';
import React, { createContext } from 'react';
import { pointsMap } from '../helpers/helpers';
const SupabaseContext = createContext(null);
SupabaseContext.displayName = 'Supabase Context';
const useContext = () => {
    const [user, setUser] = React.useState(null);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [driversMap, setDriversMap] = React.useState(new Map());
    const [racesMap, setRacesMap] = React.useState(new Map());
    const [raceResultsMap, setRaceResultsMap] = React.useState(new Map());
    const [raceResultsDriverMap, setRaceResultsDriverMap] = React.useState(new Map());
    const [driversIdMap, setDriverIdMap] = React.useState(new Map());
    const [qualiResultsMap, setQualiResultsMap] = React.useState(new Map());
    const [p10PointsMap, setP10PointsMap] = React.useState(new Map());
    const [f1PointsMap, setF1PointsMap] = React.useState(new Map());
    const [constructorsPointsMap, setConstructorsPointsMap] = React.useState(new Map());
    const [races, setRaces] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const supabaseUrl = 'https://msrqldgafbaagfcxbcyv.supabase.co';
    const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1zcnFsZGdhZmJhYWdmY3hiY3l2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzg1OTE1NjYsImV4cCI6MTk5NDE2NzU2Nn0.5zGGuJVpBoYLUCr53Haz671UMJw0AtFAHJo9giqnYYA';
    const client = createClient(supabaseUrl, supabaseKey);
    const checkReset = () => __awaiter(void 0, void 0, void 0, function* () {
        client.auth.onAuthStateChange((event, session) => __awaiter(void 0, void 0, void 0, function* () {
            if (event === 'PASSWORD_RECOVERY') {
                localStorage.setItem('reset', 'true');
            }
        }));
    });
    const checkUser = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { data: { user }, } = yield client.auth.getUser();
        if (user) {
            const defaultName = (_a = user.email) === null || _a === void 0 ? void 0 : _a.split('@')[0];
            try {
                yield client
                    .from('users')
                    .upsert({ email: user.email, uuid: user.id, name: defaultName }, { onConflict: 'uuid', ignoreDuplicates: true });
            }
            catch (exception) { }
        }
        setUser(user);
    });
    const checkAdmin = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield client.rpc('get_admins');
        setIsAdmin(data.length > 0);
    });
    const setData = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data: drivers } = yield client
            .from('drivers')
            .select('*')
            .not('id', 'eq', 241)
            .not('id', 'eq', 266)
            .not('year', 'eq', 2023)
            .order('constructor')
            .order('id');
        const { data: dbRaces } = yield client
            .from('races')
            .select('*')
            .not('year', 'eq', 2023)
            .order('round_number', { ascending: true });
        const { data: raceResults } = yield client
            .from('race_results')
            .select('*')
            .not('year', 'eq', 2023)
            .order('position', { ascending: true });
        const { data: qualiResults } = yield client
            .from('quali_results')
            .select('*')
            .not('year', 'eq', 2023)
            .order('race_id', { ascending: true })
            .order('position', { ascending: true });
        const { data: constructors } = yield client
            .from('constructor')
            .select('*')
            .not('year', 'eq', 2023);
        if (!drivers || !dbRaces || !raceResults || !qualiResults)
            throw new Error('failed to initialize');
        const dMap = new Map();
        const dIdMap = new Map();
        const p10DriverTotalPointsMap = new Map();
        const f1DriverTotalPointsMap = new Map();
        const constructorsTotalPointsMap = new Map();
        for (const driver of drivers) {
            dMap.set(driver.id, driver);
            dIdMap.set(driver.driver_id, driver);
            p10DriverTotalPointsMap.set(driver.id, 0);
            f1DriverTotalPointsMap.set(driver.id, 0);
        }
        for (const constructor of constructors) {
            constructorsTotalPointsMap.set(constructor.constructor_id, 0);
        }
        const rrdMap = new Map();
        const rrMap = new Map();
        const rMap = new Map();
        const qMap = new Map();
        for (const race of dbRaces) {
            rMap.set(race.id, race);
            rrMap.set(race.id, []);
            qMap.set(race.id, []);
            rrdMap.set(race.id, new Map());
        }
        for (const raceResult of raceResults) {
            const dId = raceResult.driver_id;
            rrMap.get(raceResult.race_id).push(raceResult);
            rrdMap.get(raceResult.race_id).set(dId, raceResult);
            if (raceResult.position) {
                p10DriverTotalPointsMap.set(dId, p10DriverTotalPointsMap.get(dId) + pointsMap[raceResult.position]);
                f1DriverTotalPointsMap.set(dId, f1DriverTotalPointsMap.get(dId) + raceResult.points);
                constructorsTotalPointsMap.set(dMap.get(dId).constructor, constructorsTotalPointsMap.get(dMap.get(dId).constructor) +
                    raceResult.points);
            }
        }
        for (const qualiResult of qualiResults) {
            qMap.get(qualiResult.race_id).push(qualiResult);
        }
        setRacesMap(rMap);
        setRaces(dbRaces);
        setRaceResultsMap(rrMap);
        setRaceResultsDriverMap(rrdMap);
        setDriversMap(dMap);
        setDriverIdMap(dIdMap);
        setQualiResultsMap(qMap);
        setP10PointsMap(p10DriverTotalPointsMap);
        setF1PointsMap(f1DriverTotalPointsMap);
        setConstructorsPointsMap(constructorsTotalPointsMap);
    });
    const loadDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        yield checkReset();
        yield checkUser();
        yield setData();
        yield checkAdmin();
        setLoading(false);
    });
    React.useEffect(() => {
        setLoading(true);
        loadDetails();
    }, []);
    return {
        client,
        user,
        setUser,
        driversMap,
        driversIdMap,
        racesMap,
        raceResultsMap,
        raceResultsDriverMap,
        races,
        loading,
        qualiResultsMap,
        p10PointsMap,
        f1PointsMap,
        constructorsPointsMap,
        isAdmin: isAdmin,
    };
};
export const useSupabaseContext = () => {
    const context = React.useContext(SupabaseContext);
    if (!context) {
        throw new Error('Supabase Context undefined. Make sure you use the SupabaseProvider before using the context.');
    }
    return context;
};
export const SupabaseProvider = ({ children }) => {
    const value = useContext();
    return (React.createElement(SupabaseContext.Provider, { value: value }, children));
};
