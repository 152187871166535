import { PriorityHigh } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography, } from '@mui/material';
import React from 'react';
import { useSupabaseContext } from '../../../contexts/SupabaseContext';
import { timeLeftString } from '../../../helpers/helpers';
import useCountdown from '../../../hooks/useCountdown';
import Picker from '../Picker/Picker';
import ResultsTable from '../Results/ResultsTable';
import styles from './RaceAccordion.module.scss';
const RaceAccordion = ({ race, nextRaceRoundId, leagueResultsMap, submitDriver, leagueMembers, }) => {
    var _a, _b;
    const { user, driversMap } = useSupabaseContext();
    const passedQualiDate = (race) => {
        const raceDate = Date.parse(`${race.quali_date} ${race.quali_time}`);
        return raceDate < Date.now();
    };
    const { days, hours, minutes, seconds } = useCountdown(new Date(`${race.quali_date} ${race.quali_time}`));
    return (React.createElement(Accordion, { key: race.race_name, defaultExpanded: nextRaceRoundId === race.id, elevation: 2 },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), sx: {
                '& .MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
                },
            } },
            React.createElement("div", { className: styles.accordionText },
                React.createElement(Typography, { className: styles.raceName },
                    race.race_name,
                    ' ',
                    nextRaceRoundId === race.id &&
                        ((_a = leagueResultsMap.get(user.id).get(race.id)) === null || _a === void 0 ? void 0 : _a.driver_id) ===
                            null && (React.createElement(Tooltip, { title: "Lock in a driver before the qualifying starts!" },
                        React.createElement(PriorityHigh, { color: "error" })))),
                !passedQualiDate(race) && (React.createElement(Typography, { variant: "body2" }, `Picks end in ${timeLeftString(days, hours, minutes, seconds)}`)))),
        React.createElement(AccordionDetails, null,
            !passedQualiDate(race) && (React.createElement("div", { className: styles.racePicks },
                React.createElement(Picker, { id: race.race_name, rowId: (_b = leagueResultsMap.get(user.id).get(race.id)) === null || _b === void 0 ? void 0 : _b.id, drivers: driversMap, submitHandler: submitDriver, resultsRow: leagueResultsMap.get(user.id).get(race.id) }))),
            React.createElement(ResultsTable, { leagueMembers: leagueMembers, leagueResultsMap: leagueResultsMap, race: race }))));
};
export default RaceAccordion;
