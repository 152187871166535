var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Divider, List, MenuItem, Select, Typography, } from '@mui/material';
import React, { useState } from 'react';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import { pointsMap } from '../../helpers/helpers';
import { getRaceByRoundNumber, getRaceResultsByRound, getTable, } from '../../services/database';
import { setConstructors, setDrivers, setQualiResultsByRound, setRaceResultsByRound, setRaces, } from '../../services/f1';
import Loader from '../loader/Loader';
import styles from './AdminPanel.module.scss';
const AdminPanel = () => {
    const [round, setRound] = useState(1);
    const { client, driversIdMap, races } = useSupabaseContext();
    const [leaguesMap, setLeaguesMap] = React.useState(null);
    const [leagueMembersMap, setLeagueMembersMap] = React.useState(null);
    const [userMap, setUserMap] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const { sendAlert } = useUtilsContext();
    const setupLeagues = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data: leagues } = yield getTable(client, 'leagues');
        const tLM = new Map();
        const tLMM = new Map();
        for (const league of leagues) {
            tLM.set(league.id, league);
            tLMM.set(league.id, []);
        }
        const { data: league_members } = yield getTable(client, 'league_members');
        for (const member of league_members) {
            tLMM.get(member.league_id).push(member);
        }
        const { data: users } = yield getTable(client, 'users');
        const tUM = new Map();
        for (const user of users) {
            tUM.set(user.uuid, user);
        }
        setLeaguesMap(tLM);
        setLeagueMembersMap(tLMM);
        setUserMap(tUM);
        setLoading(false);
    });
    const calculatePoints = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const { data: race } = yield getRaceByRoundNumber(client, round);
        const raceId = race[0].id;
        const { data } = yield getRaceResultsByRound(client, round);
        const driverResultMap = new Map();
        let raceDnfDriverId = 266;
        const lastPos = data.length;
        for (const result of data) {
            driverResultMap.set(result.driver_id, result);
            if (result.position === lastPos && isDnf(result.status)) {
                raceDnfDriverId = result.driver_id;
            }
        }
        const { data: leagueResults } = yield client
            .from('league_results')
            .select('*')
            .eq('race_id', raceId);
        let updatedCount = 0;
        for (const result of leagueResults) {
            const position = (_a = driverResultMap.get(result === null || result === void 0 ? void 0 : result.driver_id)) === null || _a === void 0 ? void 0 : _a.position;
            if (!position)
                continue;
            const pointsGained = (_b = pointsMap[position]) !== null && _b !== void 0 ? _b : 0;
            const dnfPointsGained = calcDnfPoints(result === null || result === void 0 ? void 0 : result.dnf_driver_id, raceDnfDriverId);
            console.log(pointsGained, dnfPointsGained);
            yield client
                .from('league_results')
                .update({
                points_gained: pointsGained,
                dnf_points_gained: dnfPointsGained,
            })
                .eq('id', result.id);
            updatedCount++;
        }
        sendAlert(`Calculated results for ${updatedCount} players`);
    });
    const isDnf = (status) => {
        const lowered = status.toLowerCase();
        return !(lowered.includes('lap') || lowered.includes('finish'));
    };
    const calcDnfPoints = (pickedDriverId, dnfDriverId) => {
        if (null)
            return 0;
        if (pickedDriverId != dnfDriverId)
            return 0;
        if (pickedDriverId === 266)
            return 25;
        return 10;
    };
    const setRaceResultsByRoundClickHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield setRaceResultsByRound(client, round, driversIdMap);
            sendAlert('success');
        }
        catch (e) {
            sendAlert(e.message, 'error');
        }
    });
    const setQualiByRoundClickHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield setQualiResultsByRound(client, round, driversIdMap);
            sendAlert('success');
        }
        catch (e) {
            sendAlert(e.message, 'error');
        }
    });
    React.useEffect(() => {
        setupLeagues();
    }, []);
    if (loading)
        return React.createElement(Loader, null);
    return (React.createElement(List, null,
        React.createElement(Typography, { variant: "body1", mr: 2 }, "Setup"),
        React.createElement("div", { className: styles.buttonContainer },
            React.createElement(Button, { onClick: () => setRaces(client), variant: "contained" }, "Set Races"),
            React.createElement(Button, { onClick: () => setConstructors(client), variant: "contained" }, "Set Constructors"),
            React.createElement(Button, { onClick: () => setDrivers(client), variant: "contained" }, "Set Drivers")),
        React.createElement(Divider, null),
        React.createElement("div", null,
            React.createElement(Select, { value: round, onChange: (e) => setRound(Number(e.target.value)) }, Array.from(races.values()).map((key, index) => (React.createElement(MenuItem, { value: index + 1, key: key.id },
                React.createElement("span", null,
                    index + 1,
                    ": ",
                    key.race_name)))))),
        React.createElement("div", { className: styles.buttonContainer },
            React.createElement(Button, { onClick: setQualiByRoundClickHandler, variant: "contained" }, "Quali"),
            React.createElement(Button, { onClick: setRaceResultsByRoundClickHandler, variant: "contained" }, "Race Results"),
            React.createElement(Button, { onClick: () => calculatePoints(), variant: "contained" }, "Calculate Points")),
        React.createElement(Divider, null),
        Array.from(leaguesMap.entries()).map(([leagueId, league]) => (React.createElement(React.Fragment, { key: leagueId },
            React.createElement("div", null,
                React.createElement(Typography, { variant: "h4" }, league.name),
                leagueMembersMap.get(leagueId).map((member) => (React.createElement("div", { key: member.user_uuid + leagueId },
                    React.createElement(Typography, { variant: "body1" }, userMap.get(member.user_uuid).name))))),
            React.createElement(Divider, null))))));
};
export default AdminPanel;
