import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseContext } from './contexts/SupabaseContext';
const ProtectedRoute = ({ admin = false, children, }) => {
    const { user, isAdmin } = useSupabaseContext();
    const navigate = useNavigate();
    const checkUser = () => {
        if (!user) {
            navigate('/');
        }
        if (admin && !isAdmin) {
            navigate('/');
        }
    };
    React.useEffect(() => {
        checkUser();
    }, []);
    if (!user)
        return null;
    return React.createElement(React.Fragment, null, children);
};
export default ProtectedRoute;
