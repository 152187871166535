var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const joinLeague = (client, user, code) => __awaiter(void 0, void 0, void 0, function* () {
    const { data } = yield client.rpc('get_league_for_invite_code', {
        invite: code,
    });
    if (data.length !== 1)
        throw new Error('League invite not found');
    const { error } = yield client.rpc('join_league', {
        i_code: code,
    });
    if (error)
        throw new Error('An error joining the league as occured');
});
export const insertIntoRaces = (client, race) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    yield client.from('races').upsert([
        {
            race_name: race.raceName,
            round_number: race.round,
            year: race.season,
            date: race.date,
            time: race.time,
            fp1_date: race.FirstPractice.date,
            fp1_time: race.FirstPractice.time,
            fp2_date: race.SecondPractice.date,
            fp2_time: race.SecondPractice.time,
            fp3_date: (_a = race.ThirdPractice) === null || _a === void 0 ? void 0 : _a.date,
            fp3_time: (_b = race.ThirdPractice) === null || _b === void 0 ? void 0 : _b.time,
            sprint_date: (_c = race.Sprint) === null || _c === void 0 ? void 0 : _c.date,
            sprint_time: (_d = race.Sprint) === null || _d === void 0 ? void 0 : _d.time,
            quali_date: race.Qualifying.date,
            quali_time: race.Qualifying.time,
        },
    ], { onConflict: 'race_name', ignoreDuplicates: false });
});
export const insertIntoConstructors = (client, constructor) => __awaiter(void 0, void 0, void 0, function* () {
    yield client.from('constructor').upsert([
        {
            constructor_id: constructor.constructorId,
            name: constructor.name,
            year: 2024,
            index: constructor.constructorId + '_' + '2024',
        },
    ], { onConflict: 'index', ignoreDuplicates: true });
});
export const insertIntoDrivers = (client, driver) => __awaiter(void 0, void 0, void 0, function* () {
    yield client.from('drivers').upsert([
        {
            driver_id: driver.driverId.trim(),
            last_name: driver.familyName,
            given_name: driver.givenName,
        },
    ], { onConflict: 'driver_id', ignoreDuplicates: true });
});
export const updateRaceResultWithFinish = (client, result, race_id, driversIdMap, year) => __awaiter(void 0, void 0, void 0, function* () {
    yield client.from('race_results').upsert([
        {
            race_id: race_id,
            position: result.position,
            status: result.status,
            driver_id: driversIdMap.get(result.Driver.driverId.trim()).id,
            unique_index: `${race_id}_${driversIdMap.get(result.Driver.driverId.trim()).id}`,
            points: result.points,
            year: year,
        },
    ], { onConflict: 'unique_index', ignoreDuplicates: false });
});
export const updateQualiResultWithFinish = (client, result, race_id, driverId, year) => __awaiter(void 0, void 0, void 0, function* () {
    yield client.from('quali_results').upsert([
        {
            race_id: race_id,
            position: result.position,
            driver_id: driverId,
            unique_index: `${race_id}_${driverId}`,
            q1: result.Q1,
            q2: result.Q2,
            q3: result.Q3,
            year: year,
        },
    ], { onConflict: 'unique_index', ignoreDuplicates: false });
});
export const getRaceByRoundNumber = (client, roundNumber) => __awaiter(void 0, void 0, void 0, function* () {
    return yield client
        .from('races')
        .select('id')
        .eq('round_number', roundNumber)
        .eq('year', 2024);
});
export const getRaceResultsByRaceId = (client, raceId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield client.from('race_results').select('*').eq('race_id', raceId);
});
export const getTable = (client, table) => __awaiter(void 0, void 0, void 0, function* () {
    return yield client.from(table).select('*');
});
export const getRaceResultsByRound = (client, roundNumber) => __awaiter(void 0, void 0, void 0, function* () {
    const { data } = yield getRaceByRoundNumber(client, roundNumber);
    const raceId = data[0].id;
    return yield getRaceResultsByRaceId(client, raceId);
});
