var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, TextField, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import styles from './Contact.module.scss';
const Contact = () => {
    const { client, user } = useSupabaseContext();
    const { sendAlert } = useUtilsContext();
    const messageRef = useRef();
    const emailRef = useRef();
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!(user === null || user === void 0 ? void 0 : user.id) && !!localStorage.getItem('sentMessage')) {
            if (new Date().valueOf() < JSON.parse(localStorage.getItem('sentMessage'))) {
                sendAlert('Sorry! Please wait 60 seconds before sending another message', 'error');
                return;
            }
        }
        const message = messageRef.current.value;
        const email = emailRef.current.value;
        if (message.length === 0) {
            sendAlert('Please enter a message', 'error');
            return;
        }
        const { error } = yield client.from('contact_submissions').insert({
            user_uuid: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : null,
            contact_email: email,
            submission: message,
        });
        if (error) {
            sendAlert(`Unable to submit message - ${error.message}`, 'error');
        }
        else {
            sendAlert(`Thanks for leaving a message! We will look into it soon :)`);
            if (!(user === null || user === void 0 ? void 0 : user.id)) {
                localStorage.setItem('sentMessage', JSON.stringify(new Date().valueOf() + 60000));
            }
            messageRef.current.value = '';
            emailRef.current.value = '';
        }
    });
    return (React.createElement("div", { className: `${styles.container} fadeIn` },
        React.createElement(Typography, { variant: "h4" }, "Contact Us"),
        React.createElement(Typography, { variant: "subtitle1" }, "Leave any questions, feedback, or backfill requests down below!"),
        React.createElement(Typography, { variant: "subtitle2" },
            "For backfill requests, you ",
            React.createElement("strong", null, "must"),
            " be logged in, and provide details about the league name, race and driver picks for each player e.g."),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "subtitle2", fontStyle: "italic" }, "League name"),
            React.createElement(Typography, { variant: "subtitle2", fontStyle: "italic" }, "Bahrain, player@email.com, driver pick, dnf pick"),
            React.createElement(Typography, { variant: "subtitle2", fontStyle: "italic" }, "Saudi Arabian, player2@email.com, driver pick, dnf pick")),
        React.createElement(TextField, { label: "Contact email", autoComplete: "email", fullWidth: true, inputRef: emailRef }),
        React.createElement(TextField, { fullWidth: true, required: true, label: "Message", autoComplete: "off", multiline: true, minRows: 3, inputRef: messageRef }),
        React.createElement(Button, { variant: "contained", onClick: submit }, "Submit")));
};
export default Contact;
