import { Button, FormControl, InputLabel, MenuItem, Select, } from '@mui/material';
import React from 'react';
import styles from './Picker.module.scss';
const Picker = ({ id, drivers, submitHandler, resultsRow, rowId, }) => {
    var _a, _b;
    const [driver, setDriver] = React.useState((_a = resultsRow === null || resultsRow === void 0 ? void 0 : resultsRow.driver_id) !== null && _a !== void 0 ? _a : -1);
    const [dnfDriver, setDnfDriver] = React.useState((_b = resultsRow === null || resultsRow === void 0 ? void 0 : resultsRow.dnf_driver_id) !== null && _b !== void 0 ? _b : -1);
    const handleChange = (e, type) => {
        if (type === 'p10') {
            setDriver(Number(e.target.value));
        }
        else {
            setDnfDriver(Number(e.target.value));
        }
    };
    const handleSubmit = () => {
        if (driver === -1)
            return;
        if (dnfDriver === -1)
            return;
        submitHandler(driver, dnfDriver, rowId);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.container },
            React.createElement(FormControl, { key: id, sx: {
                    width: 200,
                } },
                React.createElement(InputLabel, { id: `picker-label-${id}` }, "P10 Pick"),
                React.createElement(Select, { labelId: `picker-label-${id}`, value: driver.toString(), label: "P10 Pick", onChange: (e) => handleChange(e, 'p10'), sx: {
                        '& .MuiSelect-select': {
                            display: 'flex',
                            columnGap: '0.5rem',
                            alignItems: 'center',
                        },
                    } },
                    React.createElement(MenuItem, { value: -1 }, "Pick a driver"),
                    Array.from(drivers.values()).map((key) => (React.createElement(MenuItem, { value: key.id, key: key.id, className: styles.picker },
                        React.createElement("span", null,
                            key.given_name,
                            " ",
                            key.last_name),
                        React.createElement("img", { src: `images/${key.constructor}.png`, height: 20 })))))),
            React.createElement(FormControl, { key: id + 'dnf', sx: {
                    width: 200,
                } },
                React.createElement(InputLabel, { id: `picker-label-${id}-dnf` }, "DNF Pick"),
                React.createElement(Select, { labelId: `picker-label-${id}-dnf`, value: dnfDriver.toString(), label: "DNF Pick", onChange: (e) => handleChange(e, 'dnf'), sx: {
                        '& .MuiSelect-select': {
                            display: 'flex',
                            columnGap: '0.5rem',
                            alignItems: 'center',
                        },
                    } },
                    React.createElement(MenuItem, { value: -1 }, "Pick a driver"),
                    Array.from(drivers.values()).map((key) => (React.createElement(MenuItem, { value: key.id, key: key.id, className: styles.picker },
                        React.createElement("span", null,
                            key.given_name,
                            " ",
                            key.last_name),
                        React.createElement("img", { src: `images/${key.constructor}.png`, height: 20 })))),
                    React.createElement(MenuItem, { value: 266, className: styles.picker }, "NO DNF!"))),
            React.createElement(Button, { onClick: handleSubmit, disabled: driver === -1 || dnfDriver === -1, variant: "outlined" }, "Submit"))));
};
export default Picker;
