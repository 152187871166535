import { Typography } from '@mui/material';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { useSupabaseContext, } from '../../contexts/SupabaseContext';
import styles from './Schedule.module.scss';
import ScheduleCard from './ScheduleCard/ScheduleCard';
const Schedule = () => {
    const { races: allRaces } = useSupabaseContext();
    const races = allRaces.filter((race) => race.year === 2024);
    const onChange = (inView, entry) => {
        if (inView) {
            entry.target.classList.add('fadeIn');
        }
    };
    const passedRaceDate = (race) => {
        const raceDate = Date.parse(`${race.date} ${race.time}`);
        return raceDate < Date.now();
    };
    const nextRaceIndex = races.findIndex((race) => !passedRaceDate(race));
    return (React.createElement("div", { className: 'fadeIn' },
        nextRaceIndex !== -1 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.heading },
                React.createElement(Typography, { variant: "h4", sx: { mb: 2 } }, "Upcoming Race Schedule")),
            React.createElement("div", { className: styles.racesContainer }, races.slice(nextRaceIndex, undefined).map((race) => (React.createElement(InView, { onChange: onChange, style: { width: '100%' }, key: race.id, className: "hidden" },
                React.createElement(ScheduleCard, { race: race }))))))),
        nextRaceIndex !== 0 && (React.createElement("div", { className: styles.heading },
            React.createElement(Typography, { variant: "h4", sx: { mb: 2, mt: 2 } }, "Past Race Schedule"))),
        React.createElement("div", { className: styles.racesContainer }, races.slice(0, nextRaceIndex).map((race) => (React.createElement(InView, { onChange: onChange, style: { width: '100%' }, key: race.id, className: "hidden" },
            React.createElement(ScheduleCard, { race: race })))))));
};
export default Schedule;
