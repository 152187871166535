var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import { joinLeague } from '../../services/database';
import Loader from '../loader/Loader';
import styles from './Join.module.scss';
const Join = () => {
    const { inviteCode } = useParams();
    const { client, user } = useSupabaseContext();
    const { sendAlert } = useUtilsContext();
    const [invitedLeague, setInvitedLeague] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    const getLeagueForInvite = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield client.rpc('get_league_for_invite_code', {
            invite: inviteCode,
        });
        if (data.length === 1) {
            setInvitedLeague(data[0]);
        }
        setLoading(false);
    });
    React.useEffect(() => {
        if (!inviteCode) {
            navigate('/');
        }
        getLeagueForInvite();
    }, []);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (user) {
            try {
                yield joinLeague(client, user, inviteCode);
                sendAlert('Successfully joined league!');
                navigate('/leagues');
            }
            catch (e) {
                sendAlert('Failed to join league', 'error');
            }
        }
        else {
            localStorage.setItem('inviteToken', JSON.stringify({
                code: inviteCode,
                time: new Date().valueOf() + 1800000, // new invite code lasts 30 mins for auto redirect
            }));
            client.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: window.location.origin + '/join/' + inviteCode,
                },
            });
        }
    });
    if (loading)
        return React.createElement(Loader, null);
    if (!invitedLeague)
        return (React.createElement(Typography, { variant: "body1" }, "Invalid code :/ Double check your invite code and try again"));
    return (React.createElement("div", { className: `${styles.container} fadeIn` },
        React.createElement(Typography, { variant: "h4" }, "You have been invited to join"),
        React.createElement(Typography, { variant: "h5", sx: { mt: 6, mb: 6 } },
            invitedLeague.name,
            "!"),
        !user && (React.createElement(Typography, { variant: "body1" }, "You must be signed in to join the league.")),
        React.createElement("div", null,
            React.createElement(Button, { onClick: handleClick, variant: "contained" }, user ? 'Join league' : 'Sign in with Google'))));
};
export default Join;
