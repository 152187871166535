var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Error } from '@mui/icons-material';
import { Button, Divider, Link, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import Loader from '../loader/Loader';
import styles from './Login.module.scss';
const signIn = (client) => {
    client.auth.signInWithOAuth({
        provider: 'google',
        options: {
            redirectTo: window.location.origin,
        },
    });
};
const Login = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [signUpPassword, setSignUpPassword] = React.useState('');
    const [loginState, setLoginState] = React.useState('login');
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const { client, setUser } = useSupabaseContext();
    const { sendAlert } = useUtilsContext();
    const navigate = useNavigate();
    const toggleState = () => {
        setLoginState((prev) => (prev === 'login' ? 'signup' : 'login'));
        setEmail('');
        setPassword('');
        setSignUpPassword('');
        setError('');
    };
    const isValidEmail = () => {
        return !!email.match('^[^@]+@[^@]+.[^@]+$');
    };
    const validFields = () => {
        if (!isValidEmail()) {
            setError('Please enter a valid email');
            return false;
        }
        if (loginState === 'signup' && password !== signUpPassword) {
            setError('Passwords do not match');
            return false;
        }
        if (password.length < 6) {
            setError('Password must be at least 6 characters');
            return false;
        }
        return true;
    };
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!validFields())
            return;
        const { data, error } = yield client.auth.signInWithPassword({
            email,
            password,
        });
        if (error) {
            setError(error.message);
        }
        else {
            // handle login
            if (data.user && data.session) {
                setUser(data.user);
                navigate('/');
            }
        }
    });
    const handleSignUp = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!validFields())
            return;
        const { data, error } = yield client.auth.signUp({
            email,
            password,
            options: {
                emailRedirectTo: 'https://p10racing.app/login',
            },
        });
        if (error) {
            setError(error.message);
        }
        else {
            toggleState();
            sendAlert('Successfully signed up! Please verify your email before logging in', 'success', null);
        }
    });
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        setLoading(loginState === 'login' ? 'Logging you in...' : 'Signing you up...');
        if (loginState === 'login') {
            yield handleLogin();
        }
        else {
            yield handleSignUp();
        }
        setLoading(null);
    });
    const forgotPasswordHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isValidEmail()) {
            sendAlert('Please enter a valid email', 'error');
            return;
        }
        setLoading('Sending forgot password email...');
        const { data, error } = yield client.auth.resetPasswordForEmail(email, {
            redirectTo: 'https://p10racing.app/reset',
        });
        setLoading(null);
        if (error) {
            if (error.message.includes('AuthApiError: For security purposes, you can only request this once every 60 seconds')) {
                sendAlert('Please try again after 60 seconds', 'error');
            }
            else {
                sendAlert('An unknown error occurred while sending forgot password email', 'error');
            }
        }
        else {
            sendAlert(`An email has been sent to ${email}`);
        }
    });
    return (React.createElement("div", { className: `${styles.container} fadeIn` },
        React.createElement(Typography, { variant: "h4", width: "100%", sx: { userSelect: 'none' } }, "Login"),
        React.createElement(Button, { variant: "contained", onClick: () => signIn(client) }, "Sign in with Google"),
        React.createElement(Divider, { flexItem: true }),
        loading ? (React.createElement("div", { className: styles.loaderContainer },
            React.createElement(Loader, { text: loading }))) : (React.createElement("div", { className: styles.manualContainer },
            React.createElement(TextField, { label: "Email", fullWidth: true, autoComplete: "email", placeholder: "Your login email", onChange: (e) => setEmail(e.target.value), value: email, type: "email" }, "Email"),
            React.createElement(TextField, { label: loginState === 'login' ? 'Password' : 'Choose a password', placeholder: "At least 6 characters", type: "password", fullWidth: true, value: password, onChange: (e) => setPassword(e.target.value) }, "Password"),
            loginState === 'signup' && (React.createElement(TextField, { label: "Re-enter your password", type: "password", fullWidth: true, value: signUpPassword, onChange: (e) => setSignUpPassword(e.target.value) }, "Password")),
            error && (React.createElement(Typography, { variant: "subtitle1", className: styles.error },
                React.createElement(Error, { color: "error" }),
                React.createElement("span", null, error))),
            React.createElement(Button, { variant: "contained", className: styles.button, onClick: handleSubmit }, loginState === 'login' ? 'Login' : 'Signup'),
            React.createElement(Typography, { variant: "subtitle2", sx: { userSelect: 'none' } },
                loginState === 'login'
                    ? 'No account?'
                    : 'Already have an account?',
                ' ',
                React.createElement(Link, { onClick: toggleState, underline: "none", className: styles.link }, loginState === 'login' ? 'Sign up' : 'Login')),
            React.createElement(Link, { underline: "none", className: styles.link, variant: "subtitle2", onClick: forgotPasswordHandler }, "Forgot your password?")))));
};
export default Login;
