import { DarkMode, LightMode } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Toolbar, } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseContext } from '../../contexts/SupabaseContext';
import { useUtilsContext } from '../../contexts/UtilsContext';
import { navItemToPathMap } from '../../helpers/helpers';
import NestedNavItem from './NestedNavItem';
const Navigation = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const { user, client, setUser, isAdmin } = useSupabaseContext();
    const { mode, toggleColorMode } = useUtilsContext();
    const [navItems, setNavItems] = React.useState([]);
    const navigate = useNavigate();
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const menuOpen = Boolean(menuAnchor);
    const [menuItems, setMenuItems] = React.useState([]);
    const handleDrawerToggle = () => {
        setDrawerOpen((prev) => !prev);
    };
    const handleMenuOpen = (event, nav) => {
        setMenuItems(nav.menu);
        setMenuAnchor(event.currentTarget);
    };
    const handleMenuClick = (menuItemString) => {
        setMenuAnchor(null);
        handleNavigation(menuItemString);
        if (drawerOpen) {
            setDrawerOpen(false);
        }
    };
    const handleClick = (e, item) => {
        if (item.menu) {
            handleMenuOpen(e, item);
        }
        else {
            navigate('/' + navItemToPathMap[item.name]);
            if (drawerOpen) {
                setDrawerOpen(false);
            }
        }
    };
    const handleNavigation = (navItemString) => {
        if (navItemString === 'Logout') {
            client.auth.signOut();
            setUser(null);
            navigate('/');
        }
        else {
            navigate(`/${navItemToPathMap[navItemString]}`);
        }
    };
    const drawer = (React.createElement(Box, { sx: { textAlign: 'center', mb: 3 } },
        React.createElement(List, null,
            React.createElement(ListItem, { disablePadding: true },
                React.createElement(ListItemButton, { sx: { textAlign: 'center' }, onClick: () => handleMenuClick('P10 Racing') },
                    React.createElement(ListItemText, { primary: "P10 Racing" })))),
        React.createElement(Divider, null),
        React.createElement(List, null,
            navItems.map((item) => item.menu ? (React.createElement(NestedNavItem, { item: item, handleMenuClick: handleMenuClick, key: item.name })) : (React.createElement(ListItem, { key: item.name, disablePadding: true },
                React.createElement(ListItemButton, { sx: { textAlign: 'center' }, onClick: (e) => handleClick(e, item) },
                    React.createElement(ListItemText, { primary: item.name }))))),
            React.createElement(ListItem, { disablePadding: true },
                React.createElement(ListItemButton, { sx: { textAlign: 'center', justifyContent: 'center' }, onClick: toggleColorMode }, mode === 'light' ? React.createElement(LightMode, null) : React.createElement(DarkMode, null))))));
    React.useEffect(() => {
        const temp = [
            {
                name: 'F1 Info',
                menu: ['Schedule', 'Qualifying', 'Results', 'Standings'],
            },
        ];
        if (user) {
            const profileMenu = ['Edit Profile'];
            if (user.app_metadata.provider === 'email')
                profileMenu.push('Change Password');
            if (isAdmin)
                profileMenu.push('Admin');
            profileMenu.push('Logout');
            temp.push({ name: 'Leagues' }, { name: 'Help', menu: ['About', 'FAQs', 'Contact Us'] }, { name: 'Profile', menu: profileMenu });
        }
        else {
            temp.push({ name: 'Login' }, { name: 'Help', menu: ['About', 'FAQs', 'Contact Us'] });
        }
        temp.push();
        setNavItems(temp);
    }, [user]);
    const topbarTextColor = mode === 'light' ? '#262626' : '#FFF';
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, { component: "nav", sx: { background: 'transparent', zIndex: 100 }, elevation: 0, position: "static" },
            React.createElement(Toolbar, { sx: {
                    flexDirection: { xs: 'row-reverse', sm: 'row' },
                    justifyContent: 'space-between',
                } },
                React.createElement(IconButton, { color: "inherit", "aria-label": "open drawer", edge: "end", onClick: handleDrawerToggle, sx: {
                        ml: 2,
                        display: { sm: 'none' },
                        color: topbarTextColor,
                    } },
                    React.createElement(MenuIcon, null)),
                React.createElement(Button, { onClick: () => handleNavigation('P10 Racing'), sx: {
                        color: topbarTextColor,
                        display: { xs: 'none', sm: 'inline' },
                    } }, "P10 Racing"),
                React.createElement(Box, { sx: { display: { xs: 'none', sm: 'block' } } },
                    navItems.map((item) => (React.createElement(Button, { key: item.name, sx: { color: topbarTextColor }, onClick: (e) => handleClick(e, item) }, item.name))),
                    React.createElement(IconButton, { onClick: toggleColorMode, sx: { color: topbarTextColor, flex: 1 } }, mode === 'light' ? React.createElement(LightMode, null) : React.createElement(DarkMode, null))))),
        React.createElement(Box, { component: "nav" }),
        React.createElement(Drawer, { anchor: "right", variant: "temporary", open: drawerOpen, onClose: handleDrawerToggle, ModalProps: {
                keepMounted: true, // Better open performance on mobile.
            }, sx: {
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
            } }, drawer),
        React.createElement(Menu, { anchorEl: menuAnchor, open: menuOpen, onClose: () => setMenuAnchor(null), anchorOrigin: { horizontal: 'center', vertical: 'bottom' }, transformOrigin: { horizontal: 'center', vertical: 'top' } }, menuItems.map((item) => (React.createElement(MenuItem, { onClick: () => handleMenuClick(item), key: item }, item))))));
};
export default Navigation;
