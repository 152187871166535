import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, useMediaQuery, } from '@mui/material';
import React from 'react';
const DataTable = ({ pagination = false, headerData, rowData, secondRowData, }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const showPagination = rowData.length > 5 && pagination;
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;
    const visibleRows = React.useMemo(() => {
        return showPagination
            ? rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rowData;
    }, [page, rowsPerPage]);
    const secondVisibleRows = React.useMemo(() => {
        return showPagination
            ? secondRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : secondRowData;
    }, [page, rowsPerPage]);
    const emptyRowHeight = secondRowData ? 32 * emptyRows * 2 : 32 * emptyRows;
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sx: { width: '100%', overflow: 'auto' }, size: "small" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, headerData.map((header, index) => {
                    var _a;
                    return (React.createElement(TableCell, { align: index === 0 ? 'left' : 'right', sx: { width: header.width } }, isMobile
                        ? (_a = header.mobileHeader) !== null && _a !== void 0 ? _a : header.header
                        : header.header));
                }))),
            React.createElement(TableBody, null,
                visibleRows.map((row, i) => (React.createElement(React.Fragment, null,
                    React.createElement(TableRow, null, row.map((row, index) => {
                        var _a;
                        return (React.createElement(TableCell, { sx: {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                maxWidth: 0,
                            }, align: index > 0 ? 'right' : 'left' }, isMobile ? (_a = row.mobileData) !== null && _a !== void 0 ? _a : row.data : row.data));
                    })),
                    secondRowData && (React.createElement(TableRow, null, secondVisibleRows[i].map((row, index) => {
                        var _a;
                        return (React.createElement(TableCell, { sx: {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                maxWidth: 0,
                            }, align: index > 0 ? 'right' : 'left' }, isMobile ? (_a = row.mobileData) !== null && _a !== void 0 ? _a : row.data : row.data));
                    })))))),
                showPagination && emptyRows > 0 && (React.createElement(TableRow, { style: {
                        height: emptyRowHeight,
                    } },
                    React.createElement(TableCell, { colSpan: 6 }))))),
        showPagination && (React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25], component: "div", count: rowData.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))));
};
export default DataTable;
