import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import About from './components/about/About';
import AdminPanel from './components/admin_panel/AdminPanel';
import Contact from './components/contact/Contact';
import GenericError from './components/error/GenericError';
import Faqs from './components/faqs/Faqs';
import Home from './components/home/Home';
import Join from './components/join/Join';
import Landing from './components/landing/Landing';
import Leagues from './components/leagues/Leagues';
import Login from './components/login/Login';
import Profile from './components/profile/Profile';
import Qualifying from './components/qualifying/Qualifying';
import RaceResults from './components/raceResults/RaceResults';
import ResetPassword from './components/resetPassword/ResetPassword';
import Schedule from './components/schedule/Schedule';
import Standings from './components/standings/Standings';
export const router = createBrowserRouter([
    {
        path: '/',
        element: React.createElement(Home, null),
        errorElement: React.createElement(GenericError, null),
        children: [
            {
                path: '',
                element: React.createElement(Landing, null),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'results',
                element: React.createElement(RaceResults, null),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'leagues',
                element: (React.createElement(ProtectedRoute, null,
                    React.createElement(Leagues, null))),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'profile',
                element: (React.createElement(ProtectedRoute, null,
                    React.createElement(Profile, null))),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'admin',
                element: (React.createElement(ProtectedRoute, { admin: true },
                    React.createElement(AdminPanel, null))),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'login',
                element: React.createElement(Login, null),
                errorElement: React.createElement(GenericError, null),
            },
            { path: 'join', element: React.createElement(Join, null), errorElement: React.createElement(GenericError, null) },
            {
                path: 'join/:inviteCode',
                element: React.createElement(Join, null),
                errorElement: React.createElement(GenericError, null),
            },
            { path: 'about', element: React.createElement(About, null), errorElement: React.createElement(GenericError, null) },
            {
                path: 'qualifying',
                element: React.createElement(Qualifying, null),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'reset',
                element: (React.createElement(ProtectedRoute, null,
                    React.createElement(ResetPassword, null))),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'schedule',
                element: React.createElement(Schedule, null),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'faqs',
                element: React.createElement(Faqs, null),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: '*',
                element: React.createElement("div", null, "Page not found"),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'contact',
                element: React.createElement(Contact, null),
                errorElement: React.createElement(GenericError, null),
            },
            {
                path: 'standings',
                element: React.createElement(Standings, null),
                errorElement: React.createElement(GenericError, null),
            },
        ],
    },
]);
